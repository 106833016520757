import React, { Component } from 'react';

import '../styles/Components/Logo.css';

class Logo extends Component {
    render(){
        return (<div className = "logo-icon"></div>);
    }
}

export default Logo;