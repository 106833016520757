import React, {Component} from 'react';
import {Markup} from 'interweave';

import '../../styles/Components/ContentGeneral.css';

class MarkupTranforms extends Component{
    render() {
        return (<Markup content = {this.props.content} transform = {this.iframeTransformHandler}/>);
    }

    iframeTransformHandler(inputElement){
        if (inputElement.tagName === "IFRAME"){
            if (inputElement.src.includes("youtube.com")){
                return(
                    <iframe 
                        className="youtube-video" 
                        title={inputElement.title} 
                        src={inputElement.src} 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen>
                    </iframe>
                );
            }
            else if(inputElement.src.includes("store.steampowered.com")){
                return(
                    <iframe 
                        className="steam-widget" 
                        title={inputElement.title} 
                        src={inputElement.src} 
                        frameBorder="0" 
                        height = {inputElement.height}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture">
                    </iframe>
                );
            }
            else if (inputElement.src.includes("discordapp.com")){
                return(
                    <iframe 
                        className="discord-widget" 
                        title={inputElement.title} 
                        src={inputElement.src} 
                        frameBorder="0" 
                        height = {inputElement.height}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture">
                    </iframe>
                );
            }
        }
    }
}

export default MarkupTranforms;