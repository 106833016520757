import React, {Component} from 'react';
import {Markup} from 'interweave';

import BannerCycling from './Banner/BannerCycling';
import Copyright from './Copyright';
import BackgroundTransition from './BackgroundTransition';

import '../styles/Components/ContentGeneral.css';
import '../styles/Components/ContentAbout.css';

class ContentAbout extends Component{
    render() {
        document.title = "Design Imps";
        
        // TODO: Find a better way of dealing with this
        let cycleBanners = [];
        for(let i = 0; i < this.props.banners.length; i++){
            if (this.props.banners[i].cycleBanner === "yes"){cycleBanners.push(this.props.banners[i]);}
        }

        return (
            <div>
                <BannerCycling banners = {cycleBanners} />

                <div className = "general-content about-content">
                    <BackgroundTransition position = "bottom" />
                    
                    <div className = "general-content-display about-content-section">
                        <Markup content = {this.props.aboutContent.body} />
                    </div>
                    <div className = "vertical-fill"> </div>
                    <Copyright/>
                </div>

            </div>
        );
    }
}

export default ContentAbout;