import React, {Component} from 'react';
// import {Markup} from 'interweave';

import Copyright from './Copyright';

import '../styles/Components/ContentGeneral.css';
import '../styles/Components/ContentContact.css';
import ContactForm from './Contact/ContactForm';

class ContentContact extends Component {
    render() {
        document.title = "Design Imps - Contact Us";
        
        return (
            <div>
                <div className = "general-content-no-banner contact-content">
                    <div className = "contact-background-bar"></div>

                    <div className = "general-content-display contact-content-section">
                        <ContactForm contactContent = {this.props.contactContent} />
                    </div>                    
                    <div className = "vertical-fill"> </div>
                    <Copyright/>
                </div>

            </div>
        );
    }
}

export default ContentContact;