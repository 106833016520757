import React, { Component } from 'react';

import BannerImage from './BannerImage';
import BannerInfo from './BannerInfo';

import '../../styles/Components/Banner/Banner.css';

class Banner extends Component {
    render() {
        // if (!this.props.bannerData){return(<div></div>)}

        return (<div className = "banner-container">
            <BannerImage bannerData = {this.props.bannerData} />

            <BannerInfo bannerData = {this.props.bannerData}/>
        </div>);
    }
}

export default Banner;