import React, { Component } from 'react';

import '../styles/Components/BackgroundTransition.css';

class BackgroundTransition extends Component {
    render() {
        if (this.props.position === "top"){
            return ( <div className = "transistion-top" ></div> );
        }
        else{
            return ( <div className = "transistion-bottom" ></div> );                
        }
    }
}

export default BackgroundTransition;