import React, { Component } from 'react';

import Game from './Game';

import '../../styles/Components/Game/GameWall.css';

class GameWall extends Component {
    render() {
        return (
            <div>
                <div className = "game-wall">
                    {this.props.games
                        .filter((value, index, array) => {return value.underDevelopment === this.props.underDevelopment})
                        .map((game, index) => <Game key = {game.id} gameData = {game} />)}
                </div>
            </div>
        );
    }
}

export default GameWall;