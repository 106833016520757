import React, {Component} from 'react';
import {Markup} from 'interweave';

import Copyright from './Copyright';
import BackgroundTransition from './BackgroundTransition';

import GameWall from './Game/GameWall';

import '../styles/Components/ContentGames.css';

class ContentGames extends Component{
    render() {
        document.title = "Design Imps - Our Games";

        return (
            <div>
                <div className = "games-content-section">
                    <Markup content = {this.props.gameContent.underDevelopmentBody} />
                    <GameWall underDevelopment = "yes" games = {this.props.games} />

                    <Markup content = {this.props.gameContent.releasedBody} />
                    <GameWall underDevelopment = {null} games = {this.props.games} />

                </div>

                {/* <div className="games-content-background-bar"></div> */}

                <div className = "game-footer">
                    <BackgroundTransition position = "bottom" />
                    
                    <div className = "vertical-fill"> </div>
                    <Copyright/>
                </div>

            </div>
        );
    }

    changeBanner(){
        let index = this.state.nextIndex + 1;
        if (index >= this.props.banners.length){ index = 0; }

        this.setState({nextIndex: index})
    }
}

export default ContentGames;