import React, { Component } from 'react';

import BannerImage from './BannerImage';
import BannerInfo from './BannerInfo';

import '../../styles/Components/Banner/Banner.css';
import '../../styles/Components/Banner/BannerCycling.css';

// Get the cycling images
// https://stackoverflow.com/questions/39426083/update-react-component-every-second

class BannerCycling extends Component {    
    constructor(props){
        super(props);
        this.state = { nextIndex: 0 };

        this.handleSelectorClick = this.handleSelectorClick.bind(this);
      }

    componentDidMount() {
        this.interval = setInterval(() => this.changeBanner(), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        if (this.props.banners.length === 0){return (<div></div>)}
        
        return (
        <div>
            <div className = "banner-container">
                <BannerImage bannerData = {this.props.banners[this.state.nextIndex]} />

                <BannerInfo bannerData = {this.props.banners[this.state.nextIndex]}/>

            </div>
            
            <div className="banner-selectors">
            {this.props.banners.map((game, index) => 
                <div className={"banner-selector" + (this.state.nextIndex === index ? " active" : "")} role="button" key = {index} data-index = {index} onClick = {this.handleSelectorClick}></div>
            )}
            </div>
        </div>
        );
    }

    changeBanner(){
        let index = this.state.nextIndex + 1;
        if (index >= this.props.banners.length){ index = 0; }

        // if (this.props.banners[index].cycleBanner === "yes"){
        //     console.log("The banner for " + this.props.banners[index].gameName + " should cycle");
        // }
        // else{
        //     console.error("The banner for " + this.props.banners[index].gameName + " should NOT cycle");
        // }

        this.setState({nextIndex: index})
    }

    handleSelectorClick(e){
        let index = parseInt(e.target.dataset.index);
        this.setState({nextIndex: index})
        clearInterval(this.interval);
        this.interval = setInterval(() => this.changeBanner(), 5000);
    }
}

export default BannerCycling;