import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import Copyright from './Copyright';
import BackgroundTransition from './BackgroundTransition';

import '../styles/Components/ContentGeneral.css';
import '../styles/Components/Content404.css';
import BannerCycling from './Banner/BannerCycling';

class Content404 extends Component{
    render() {
        document.title = "Content not found";

        //TODO: Need to add a nice 404 image in the background
        let cycleBanners = [];
        for(let i = 0; i < this.props.banners.length; i++){
            if (this.props.banners[i].cycleBanner === "yes"){cycleBanners.push(this.props.banners[i]);}
        }

        return (
            <div>
                <BannerCycling banners = {cycleBanners} />

                <div className = "general-content not-found-content">
                    <BackgroundTransition position = "bottom" />
                    
                    <div className = "general-content-display not-found-content-section">
                        <p>Oops, we could not find what you were looking for <Link role = "button" to = "/">return home</Link></p>
                    </div>
                    <div className = "vertical-fill"> </div>
                    <Copyright/>
                </div>

            </div>
        );
    }
}

export default Content404;