import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import MarkupTranform from './Utilities/MarkupTransforms';
import Banner from './Banner/Banner';
import Copyright from './Copyright';
import BackgroundTransition from './BackgroundTransition';

import '../styles/Components/ContentGeneral.css';
import '../styles/Components/ContentGamePage.css';

class ContentGamePage extends Component{
    render() {
        document.title = "Design Imps - " + this.props.gamePageData.gameName;

        return (
            <div>
                <Banner bannerData = {this.props.gamePageData.banner} />

                <div className = "general-content neon-nexus-content">
                    <BackgroundTransition position = "bottom" />

                    <div className = "general-content-display neon-nexus-content-section">
                        <div className = "neon-nexus-heading">{this.props.gamePageData.gameName}</div>
                        <MarkupTranform content = {this.props.gamePageData.body}/>
                        <div className = "neon-nexu-link"><Link role = "button" to = "/DevLogs">Take a look at our development logs</Link></div>
                    </div>
                    
                    <div className = "vertical-fill"> </div>
                    <Copyright/>
                </div>
            </div>
        );
    }
}

export default ContentGamePage;