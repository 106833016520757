import React, { Component } from 'react';
import { Markup } from 'interweave';

import '../../styles/Components/DevLogs/DevLogButton.css';

class DevLogButton extends Component {
    constructor() {
        super();
        // Ensure the this context is available via the onClick event
        this.showLog = this.showLog.bind(this);
    }

    render() {
        return (
            <div>
                <div className = "log-button-container" onClick = {this.showLog} role = "button">
                    <div className="log-game-icon"><img src = {this.props.devLogData.imageLink} alt = {this.props.devLogData.title + " Game Icon"}></img></div>
                    <div className="log-info">
                        {/* <div className="log-title">{this.props.devLogData.gameName} - Dev Log #{this.props.devLogData.logNumber} - {this.props.devLogData.title}</div> */}
                        <div className="log-title">{this.props.devLogData.gameName} - {this.props.devLogData.title}</div>
                        <div className="log-excerpt"><Markup content={this.props.devLogData.excerpt}/></div>
                        <div className="log-date">{this.props.devLogData.date}</div>
                    </div>
                </div>
                <div className = "log-separator"></div>
            </div>
        );
    }

    showLog(){
        let routeTarget = "/DevLogs/" + this.props.devLogData.id;
        let history = this.props.routeProps.history;

        history.push(routeTarget);
        window.scrollTo(0, 0);
    }
}

export default DevLogButton;