import React, { Component } from 'react';
import {Markup} from 'interweave';

import '../../styles/Components/Banner/BannerInfo.css';
import { withRouter } from 'react-router-dom';

class BannerInfo extends Component {
    constructor() {
        super();
        // Ensure the this context is available via the onClick event
        this.followLink = this.followLink.bind(this);
    }

    render() {
        let textColor = "";
        let buttonColor = "";

        switch(this.props.bannerData.gameName){
            case "Neon Nexus":
                textColor = " text-cold";
                buttonColor = " border-cold";
                break;

            case "The Endless Wyrd":
                textColor = " text-warm";
                buttonColor = " border-warm";
                break;

            default:
                textColor = " text-cold";
                buttonColor = " border-cold";
                break;
        }

        return (
        <div className = "banner-info-container">
            <div className = "banner-info-section">
                <div className = {"banner-info-game-info" + textColor}>
                    <Markup content = {this.props.bannerData.body} />
                </div>
                <div className = {"banner-info-button" + textColor + buttonColor} role = "button" onClick = {this.followLink}>More Info</div>
            </div>
        </div>);
    }

    followLink(){
        if (this.props.bannerData.gameName === "Fhtagn! Tales of the Creeping Madness" ||
            this.props.bannerData.gameName === "Fhtagn!"){
            let routeTarget = "/Fhtagn";
            let history = this.props.history;

            history.push(routeTarget);
            window.scrollTo(0, 0);
        }
        else if (this.props.bannerData.gameName === "The Creeping Madness"){
            let routeTarget = "/TheCreepingMadness";
            let history = this.props.history;

            history.push(routeTarget);
            window.scrollTo(0, 0);
        }
        else if (this.props.bannerData.gameName === "The Endless Wyrd"){
            let routeTarget = "/TheEndlessWyrd";
            let history = this.props.history;

            history.push(routeTarget);
            window.scrollTo(0, 0);
        }
        else{
            var win = window.open(this.props.bannerData.gameLink, '_blank');
            win.focus();
        }
    }
}

export default withRouter(BannerInfo);