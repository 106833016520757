import React, { Component } from 'react';

import '../styles/Components/PreLoader.css';

class PreLoader extends Component {
    render() {
        return ( 
            <div id = "preloader" className = {(this.props.loading ? ("") : ("hidden"))}>
                <div className = "loading-indicator"></div>
            </div>
        );
    }
}

export default PreLoader;