import React, {Component} from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import Navigation from './Navigation';
import ContentAbout from './ContentAbout';
import ContentGames from './ContentGames';
import ContentDevLogs from './ContentDevLogs';
import ContentDevLog from './ContentDevLog';
import ContentContact from './ContentContact';
import Content404 from './Content404';
import ContentGamePage from './ContentGamePage';
import PreLoader from './PreLoader';

// Initialize google analytics page view tracking
// https://zeph.co/google-analytics-react-router
ReactGA.initialize("UA-163123751-1");

const history = createBrowserHistory();
history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

class Main extends Component {
    constructor() {
        super();
        this.loadingState = {
            aboutContent : null,
            gameContent : null,
            devLogContent : null,
            contactContent: null,
            banners : null,
            games : null,
            devLogs:  null,

            fhtagn : null,
            theCreepingMadness : null,
            theEndlessWyrd : null
        }

        this.state = {
            loading: true,

            aboutContent : {title : "", body : ""},
            gameContent : {underDevelopmentBody : "", releasedBody : ""},
            devLogContent : "",
            contactContent : "",
            banners : [{id : "", gameName : "", body : "", imageLink : "", gameLink : "", cycleBanner: ""}],
            games : [{id : "", gameName : "", body : "", gameLink : "", imageLink : "", underDevelopment  : ""}],
            devLogs: [{id : "", title : "", excerpt : "", body : "", gameName : "", gameLink : "", imageLink : "", date : "", logNumber : ""}],

            fhtagn : {id : "", gameName : "", body : "", banner : {id : "", gameName : "", body : "", imageLink : "", gameLink : ""}},
            theCreepingMadness : {id : "", gameName : "", body : "", banner : {id : "", gameName : "", body : "", imageLink : "", gameLink : ""}},
            theEndlessWyrd : {id : "", gameName : "", body : "", banner : {id : "", gameName : "", body : "", imageLink : "", gameLink : ""}}
        }

        this.gameData = {
            // Studio
            "Design Imps" :{
                gameLink : "https://designimps.com",
                gameIconLink : "https://designimps.com/imps_content/wp-content/uploads/2020/04/game-icon-design-imps.png"
            },
            // Special
            "Tew reveal trailer": {
                gameLink : "https://store.steampowered.com/app/1227330/The_Endless_Wyrd/",
                gameIconLink : "https://designimps.com/imps_content/wp-content/uploads/2020/04/game-icon-the-endless-wyrd.png"
            },
            // Under development
            "The Endless Wyrd" : {
                gameLink : "https://designimps.com/TheEndlessWyrd",
                gameIconLink : "https://designimps.com/imps_content/wp-content/uploads/2020/04/game-icon-the-endless-wyrd.png"
            },
            "The Creeping Madness" : {
                gameLink : "https://designimps.com/TheCreepingMadness",
                gameIconLink : "https://designimps.com/imps_content/wp-content/uploads/2020/04/game-icon-the-creeping-madness.png"
            },
            // Completed
            "Fhtagn!" : {
                gameLink : "https://designimps.com/Fhtagn",
                gameIconLink : "https://designimps.com/imps_content/wp-content/uploads/2020/04/game-icon-fhtagn.png"
            }
        }
    }

    componentDidMount(){
        this.fetchWordpresData();

        ReactGA.pageview(window.location.pathname);
    }
    
    render() {
        // Help with routing
        // https://blog.pshrmn.com/simple-react-router-v4-tutorial/?source=post_page---------------------------
        
        return(
            <div className = "container">
                <PreLoader loading={this.state.loading}/>

                <Router history = {history}>
                    <Navigation/>
                    
                    <Switch>
                        <Route exact path = "/" render = {() => (<ContentAbout aboutContent = {this.state.aboutContent} banners = {this.state.banners}/>)}/>
                        <Route path = "/Games" render = {() => (<ContentGames gameContent = {this.state.gameContent} games = {this.state.games} />)}/>
                        <Route exact path = "/DevLogs" render = {(routeProps) => (<ContentDevLogs routeProps = {routeProps} devLogContent = {this.state.devLogContent} devLogs = {this.state.devLogs} banners = {this.state.banners}/>)}/>
                        <Route path = "/DevLogs/:number" render = {(routeProps) => (<ContentDevLog routeProps = {routeProps} devLogs = {this.state.devLogs} banners = {this.state.banners}/>)}/>
                        <Route path = "/Contact" render = {() => (<ContentContact contactContent = {this.state.contactContent} />)}/>
                        
                        <Route path = "/Fhtagn" render = {() => (<ContentGamePage gamePageData = {this.state.fhtagn} />)}/>
                        <Route path = "/TheCreepingMadness" render = {() => (<ContentGamePage gamePageData = {this.state.theCreepingMadness} />)}/>
                        <Route path = "/TheEndlessWyrd" render = {() => (<ContentGamePage gamePageData = {this.state.theEndlessWyrd} />)}/>

                        <Route render = {() => (<Content404 banners = {this.state.banners}/>)}/>
                    </Switch>
                </Router>

            </div>
        );
    }
    
    checkLoadingCompleted(){
        if (this.loadingState.aboutContent !== null &&
            this.loadingState.gameContent !== null &&
            this.loadingState.devLogContent !== null &&
            this.loadingState.contactContent !== null &&
            this.loadingState.banners !== null &&
            this.loadingState.games !== null &&
            this.loadingState.devLogs !== null &&

            this.loadingState.fhtagn !== null &&
            this.loadingState.theCreepingMadness !== null &&
            this.loadingState.theEndlessWyrd !== null
            )
        {
            this.postLoadLinking();

            this.setState({
                loading : false,

                aboutContent : this.loadingState.aboutContent,
                gameContent : this.loadingState.gameContent,
                devLogContent : this.loadingState.devLogContent,
                contactContent : this.loadingState.contactContent,
                banners : this.loadingState.banners,
                games : this.loadingState.games,
                devLogs : this.loadingState.devLogs,

                fhtagn : this.loadingState.fhtagn, 
                theCreepingMadness : this.loadingState.theCreepingMadness,
                theEndlessWyrd : this.loadingState.theEndlessWyrd
            })
        }
    }

    postLoadLinking(){
        // Link names and logs and banners as needed
        let test = 0;
        for(let i = 0; i < this.loadingState.banners.length; i++)
        {
            if(this.loadingState.banners[i].gameName === "Fhtagn! Tales of the Creeping Madness" || this.loadingState.banners[i].gameName === this.loadingState.fhtagn.gameName){ // Using the shortend name in data
                this.loadingState.fhtagn.banner = this.loadingState.banners[i];
                test++;
            }
            else if(this.loadingState.banners[i].gameName === this.loadingState.theCreepingMadness.gameName){
                this.loadingState.theCreepingMadness.banner = this.loadingState.banners[i];
                test++;
            }
            else if(this.loadingState.banners[i].gameName === this.loadingState.theEndlessWyrd.gameName){
                this.loadingState.theEndlessWyrd.banner = this.loadingState.banners[i];
                test++;
            }
        }
    }
    
    /*************************************************************************************************************
     *                                  WORDPRESS FETCH METHODS
     *************************************************************************************************************/
    fetchWordpresData(){
        // this.DEBUG_TEST_DATA_SETUP_REMOVE_THIS();
        
        this.fetchBannerData();
        this.fetchAbountData();
        this.fetchGamePageData();
        this.fetchGamesData();
        this.fetchContactData();
        this.fetchDevLogContentData();
        this.fetchDevLogData();
        
        // Content related to games
        this.fetchFhtagnData();
        this.fetchTheCreepingMadnessData();
        this.fetchTheEndlessWyrdData();
    }

    fetchBannerData()
    {
        fetch("https://designimps.com/imps_content/wp-json/wp/v2/banners")
            .then(response => response.json())
            .then(myJSON => {
                // Now that we have the wordpress json data, we need to start building the posts (events)
                let bannerData = this.convertWpDataToBanners(myJSON);

                // with the wp data converted to posts, we update the state to have the site display the data
                this.loadingState.banners = bannerData;
                this.checkLoadingCompleted();
        });
    }

    fetchAbountData(){
        fetch("https://designimps.com/imps_content/wp-json/wp/v2/pages/7")
        .then(response => response.json())
        .then(myJSON => {
            // Now that we have the wordpress json data, we need to start building the about page
            let aboutData = this.convertWpDataToAboutData(myJSON);

            // with the wp data converted to posts, we update the state to have the site display the data
            this.loadingState.aboutContent = aboutData;
            this.checkLoadingCompleted();
        });
    }

    fetchGamePageData(){
        let gameData = {underDevelopmentBody : "", releasedBody : ""};
        // Get the data for the intro to the games that are still under development
        fetch("https://designimps.com/imps_content/wp-json/wp/v2/pages/13")
        .then(response => response.json())
        .then(myJSON => {
            // Now that we have the wordpress json data, we need to start building the game page
            gameData.underDevelopmentBody = myJSON.content.rendered
            
            // with the wp data converted to posts, we update the state to have the site display the data
            // but only if all the game data has been downloaded
            this.loadingState.gameContent = gameData;
            this.checkLoadingCompleted();
        });

        // Get the data for the intro to the games that have been released
        fetch("https://designimps.com/imps_content/wp-json/wp/v2/pages/11")
        .then(response => response.json())
        .then(myJSON => {
            // Now that we have the wordpress json data, we need to start building the game page
            gameData.releasedBody = myJSON.content.rendered

            // with the wp data converted to posts, we update the state to have the site display the data
            // but only if all the game data has been downloaded
            this.loadingState.gameContent = gameData;
            this.checkLoadingCompleted();
        });
    }

    fetchGamesData()
    {
        fetch("https://designimps.com/imps_content/wp-json/wp/v2/games")
            .then(response => response.json())
            .then(myJSON => {
                // Now that we have the wordpress json data, we need to start building the game posts
                let gamesData = this.convertWpDataToGames(myJSON);

                // with the wp data converted to posts, we update the state to have the site display the data
                this.loadingState.games = gamesData;
                this.checkLoadingCompleted();
        });
    }

    fetchContactData(){
        // Get the data for the contact info section
        fetch("https://designimps.com/imps_content/wp-json/wp/v2/pages/27")
        .then(response => response.json())
        .then(myJSON => {
            this.loadingState.contactContent = myJSON.content.rendered;
            this.checkLoadingCompleted();
        });
    }

    fetchDevLogContentData(){
        // Get the data for the intro to the games that have been released
        fetch("https://designimps.com/imps_content/wp-json/wp/v2/pages/9")
        .then(response => response.json())
        .then(myJSON => {
            this.loadingState.devLogContent = myJSON.content.rendered;
            this.checkLoadingCompleted();
        });
    }

    fetchDevLogData(){
        // Getting all the posts:
        // https://stackoverflow.com/questions/35728943/wordpress-rest-api-v2-return-all-posts
        // read through the answers, there are a few options available to us.

        fetch("https://designimps.com/imps_content/wp-json/wp/v2/dev_logs?per_page=100")
        // fetch("https://designimps.com/imps_content/wp-json/wp/v2/dev_logs/?filter[category_name]=country&filter[posts_per_page]=500")
            .then(response => response.json())
            .then(myJSON => {
                // Now that we have the wordpress json data, we need to start building the dev_log posts
                let devLogData = this.convertWpDataToDevLogData(myJSON);

                // with the wp data converted to posts, we update the state to have the site display the data
                this.loadingState.devLogs = devLogData;
                this.checkLoadingCompleted();
        });
    }

    fetchFhtagnData(){
        // Get the data for Fhtagn's page
        fetch("https://designimps.com/imps_content/wp-json/wp/v2/pages/15")
        .then(response => response.json())
        .then(myJSON => {
            // Now that we have the wordpress json data, we need to start building neon nexus' page
            let fhtagnData = this.convertWpDataToGamePageData(myJSON);

            this.loadingState.fhtagn = fhtagnData;
            this.checkLoadingCompleted();
        });
    }

    fetchTheCreepingMadnessData(){
        // Get the data for the Creeping Madness' page
        fetch("https://designimps.com/imps_content/wp-json/wp/v2/pages/19")
        .then(response => response.json())
        .then(myJSON => {
            // Now that we have the wordpress json data, we need to start building neon nexus' page
            let theCreepingMadnessData = this.convertWpDataToGamePageData(myJSON);

            this.loadingState.theCreepingMadness = theCreepingMadnessData;
            this.checkLoadingCompleted();
        });
    }

    fetchTheEndlessWyrdData(){
        // Get the data for the Endelss Wyrd's page
        fetch("https://designimps.com/imps_content/wp-json/wp/v2/pages/17")
        .then(response => response.json())
        .then(myJSON => {
            // Now that we have the wordpress json data, we need to start building neon nexus' page
            let theEndlessWyrdData = this.convertWpDataToGamePageData(myJSON);

            this.loadingState.theEndlessWyrd = theEndlessWyrdData;
            this.checkLoadingCompleted();
        });
    }

    /*************************************************************************************************************
     *                                  CONVERSION METHODS
     *************************************************************************************************************/
    convertWpDataToBanners(WpJsonData) {
        let bannerData = [];

        for (let i = 0; i < WpJsonData.length; i++){
            let newBanner = {
                id: WpJsonData[i].id,
                gameName: WpJsonData[i].title.rendered,
                body: WpJsonData[i].content.rendered,
                imageLink: WpJsonData[i].featured_image_url,
                gameLink: "",
                cycleBanner: WpJsonData[i].cycle_banner
            }

            if (newBanner.gameName === "Tew reveal trailer"){
                newBanner.gameLink = this.gameData["Tew reveal trailer"].gameLink;
            }
            
            bannerData.push(newBanner);
        }

        return bannerData;
    }
    
    convertWpDataToAboutData(WpJsonData) {
        let aboutData = {
            id: WpJsonData.id,
            title: WpJsonData.title.rendered,
            body: WpJsonData.content.rendered
        };
        
        return aboutData;
    }

    convertWpDataToGames(WpJsonData) {
        let gamesData = [];

        for (let i = 0; i < WpJsonData.length; i++){
            let newGame = {
                id: WpJsonData[i].id,
                gameName: WpJsonData[i].title.rendered,
                body: WpJsonData[i].content.rendered,
                gameLink: this.getGameLink(WpJsonData[i].title.rendered),
                imageLink: WpJsonData[i].featured_image_url,
                underDevelopment: WpJsonData[i].under_development
            }
            
            gamesData.push(newGame);
        }

        return gamesData;
    }

    convertWpDataToDevLogData(WpJsonData){
        let devLogData = [];

        let logCounter = [];

        for (let i = 0; i < WpJsonData.length; i++){
            if (logCounter[WpJsonData[i].log_game_name]){ logCounter[WpJsonData[i].log_game_name]++; }
            else {logCounter[WpJsonData[i].log_game_name] = 1;}

            let newGame = {
                id: WpJsonData[i].id,
                title: WpJsonData[i].title.rendered,
                excerpt : WpJsonData[i].excerpt.rendered,
                body : WpJsonData[i].content.rendered,
                gameName : WpJsonData[i].log_game_name,
                gameLink : this.getGameLink(WpJsonData[i].log_game_name),
                // imageLink : this.getGameIconLink(WpJsonData[i].log_game_name),
                imageLink : WpJsonData[i].featured_image_url,
                date : this.formatDate(WpJsonData[i].date),
                logNumber : logCounter[WpJsonData[i].log_game_name]
            }
            
            devLogData.push(newGame);
        }

        // Now we just need to fix the log numbers
        for (let i = 0; i < devLogData.length; i++){
            devLogData[i].logNumber = logCounter[devLogData[i].gameName]--;
        }

        return devLogData;
    }

    convertWpDataToGamePageData(WpJsonData){
        // neonNexus : {id : "", gameName : "", body : "", banner : {id : "", gameName : "", body : "", imageLink : "", gameLink : ""}}
        let gamePageData = {
            id: WpJsonData.id,
            gameName: WpJsonData.title.rendered,
            body: WpJsonData.content.rendered
            // The banner data will be added once all the data has been loaded
        };
        
        return gamePageData;
    }

    /*************************************************************************************************************
     *                                  HELPER METHODS
     *************************************************************************************************************/

    getGameLink(gameName){
        if(gameName === "Fhtagn! Tales of the Creeping Madness") { gameName = "Fhtagn!" }

        if(this.gameData[gameName]) { return this.gameData[gameName].gameLink; }
        else { return null; }
    }

    getGameIconLink(gameName){
        if(gameName === "Fhtagn! Tales of the Creeping Madness") { gameName = "Fhtagn!" }

        if(this.gameData[gameName]) { return this.gameData[gameName].gameIconLink; }
        else { return null; }
    }

    formatDate(savedDate){
        let convertedDate = new Date(savedDate);

        let formattedDate = convertedDate.getDate() + " ";
        switch(convertedDate.getMonth()){
            case 0:
                formattedDate += "January ";
                break;
            case 1:
                formattedDate += "February ";
                break;
            case 2:
                formattedDate += "March ";
                break;
            case 3:
                formattedDate += "April ";
                break;
            case 4:
                formattedDate += "May ";
                break;
            case 5:
                formattedDate += "June ";
                break;
            case 6:
                formattedDate += "July ";
                break;
            case 7:
                formattedDate += "August ";
                break;
            case 8:
                formattedDate += "September ";
                break;
            case 9:
                formattedDate += "October ";
                break;
            case 10:
                formattedDate += "November ";
                break;
            case 11:
                formattedDate += "December ";
                break;
            default:
                formattedDate += "Unknown Month "
                break;
        }
        formattedDate += convertedDate.getFullYear();

        return formattedDate;
    }
}

export default Main