import React, {Component} from 'react';
import {Markup} from 'interweave';

import BannerCycling from './Banner/BannerCycling';
import Copyright from './Copyright';
import BackgroundTransition from './BackgroundTransition';
import DevLogButton from './DevLogs/DevLogButton';

import '../styles/Components/ContentGeneral.css';
import '../styles/Components/ContentDevLogs.css';

class ContentDevLogs extends Component{
    constructor() {
        super();
        // Ensure the this context is available via the onClick event
        this.updateFilter = this.updateFilter.bind(this);

        this.state = {
            selection: "all",
            designImps: true,
            theEndlessWyrd: true,
            theCreepingMadness: true,
            fhtagn: true
        }
    }

    render() {
        document.title = "Design Imps - Development Logs";
        
        // TODO: Find a better way of dealing with this
        let cycleBanners = [];
        for(let i = 0; i < this.props.banners.length; i++){
            if (this.props.banners[i].cycleBanner === "yes"){cycleBanners.push(this.props.banners[i]);}
        }

        let visibleDevLogs = [];
        for (let i = 0; i < this.props.devLogs.length; i++){
            if (this.props.devLogs[i].gameName === "Design Imps" && this.state.designImps){visibleDevLogs.push(this.props.devLogs[i]);}
            else if (this.props.devLogs[i].gameName === "Fhtagn! Tales of the Creeping Madness" || this.props.devLogs[i].gameName === "Fhtagn!" && this.state.fhtagn){visibleDevLogs.push(this.props.devLogs[i]);}
            else if (this.props.devLogs[i].gameName === "The Endless Wyrd" && this.state.theEndlessWyrd){visibleDevLogs.push(this.props.devLogs[i]);}
            else if (this.props.devLogs[i].gameName === "The Creeping Madness" && this.state.theCreepingMadness){visibleDevLogs.push(this.props.devLogs[i]);}
        }
        
        return (
            <div>
                <BannerCycling banners = {cycleBanners} />

                <div className = "general-content dev-logs-content">
                    <BackgroundTransition position = "bottom" />
                    
                    <div className = "general-content-display dev-logs-content-section">
                        <Markup content = {this.props.devLogContent} />
                        
                        <div className = "game-selection-container">
                            <div className = "game-selection-label">Dev log filter</div>
                            <select value = {this.state.selection} className = "game-selection-dropdown" id="game-selector" name="game-selector" onChange = {this.updateFilter}>
                                <option value="all">All</option>
                                <option value="design-imps">Design Imps</option>
                                <option value="fhtagn">Fhtagn!</option>
                                <option value="the-creeping-madness">The Creeping Madness</option>
                                <option value="the-endless-wyrd">The Endless Wyrd</option>
                            </select>
                        </div>

                        <div className = "log-intro-separator"></div>
                        
                        <div className = "dev-logs-list">
                            {visibleDevLogs.map((devLog, index) => <DevLogButton routeProps = {this.props.routeProps} key = {devLog.id} devLogData = {devLog} />)}
                        </div>
                    </div>
                    <div className = "vertical-fill"> </div>
                    <Copyright/>
                </div>

            </div>
        );
    }

    updateFilter(element){
        // console.log(element.target.value);
        this.setState({
            selection: element.target.value,
            designImps: element.target.value === "design-imps" || element.target.value === "all",
            theEndlessWyrd: element.target.value === "the-endless-wyrd" || element.target.value === "all",
            theCreepingMadness: element.target.value === "the-creeping-madness" || element.target.value === "all",
            fhtagn: element.target.value === "fhtagn" || element.target.value === "all"
        })
    }
}

export default ContentDevLogs;