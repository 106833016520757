import React, { Component } from 'react';

import '../../styles/Components/Banner/BannerImage.css';

class BannerImage extends Component {
    render() {
        return ( 
            <div>
                <img className = "banner-image" src = {this.props.bannerData.imageLink} alt = {this.props.bannerData.gameName} />
            </div>
        );
    }
}

export default BannerImage;