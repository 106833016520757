import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import MarkupTranforms from './Utilities/MarkupTransforms';
import Banner from './Banner/Banner';
import Copyright from './Copyright';
import BackgroundTransition from './BackgroundTransition';

import '../styles/Components/ContentGeneral.css';
import '../styles/Components/ContentDevLog.css';
import '../styles/Components/Content404.css';

class ContentDevLog extends Component{
    render() {
        let logId = parseInt(this.props.routeProps.match.params.number);
        
        let logFound = false;
        let activeLog = {};
        for(let i = 0; i < this.props.devLogs.length; i++){
            if (this.props.devLogs[i].id === logId){
                activeLog = this.props.devLogs[i];
                logFound = true;
                break;
            }
        }
        // console.log(activeLog);
        
        let bannerIndex = 0;
        for(let i = 0; i < this.props.banners.length; i++){
            let bannerGameName = this.props.banners[i].gameName;
            // console.log(bannerGameName);

            if (bannerGameName == "Fhtagn! Tales of the Creeping Madness"){bannerGameName = "Fhtagn!"}
            
            if (bannerGameName === activeLog.gameName){
                bannerIndex = i;
                break;
            }
        }
        
        if (!logFound){return this.showLogNotFound();}

        document.title = "Design Imps - " + activeLog.gameName + " - Log #" + activeLog.logNumber;

        return (
            <div>
                <Banner bannerData = {this.props.banners[bannerIndex]} />

                <div className = "general-content dev-log-content">
                    <BackgroundTransition position = "bottom" />

                    <div className = "general-content-display dev-log-content-section">
                        <div className = "log-number"><strong>{activeLog.gameName}</strong> - Log #{activeLog.logNumber}</div>
                        <div className = "log-heading">{activeLog.title}</div>
                        <MarkupTranforms content = {activeLog.body}></MarkupTranforms>
                        <div className = "log-date">{activeLog.date}</div>
                        <div className = "log-back"><Link role = "button" to = "/DevLogs">Back to the Dev Logs</Link></div>
                    </div>
                    
                    <div className = "vertical-fill"> </div>
                    <Copyright/>
                </div>

            </div>
        );
    }

    showLogNotFound(){
        document.title = "Design Imps - Dev log not found";

        return(
            <div>
            <Banner bannerData = {this.props.banners[0]} />

            <div className = "general-content dev-log-content">
                <BackgroundTransition position = "bottom" />

                <div className = "general-content-display not-found-content-section">
                    <p>That log could not be found <Link role = "button" to = "/DevLogs">back to Dev Logs</Link></p>
                </div>
                
                <div className = "vertical-fill"> </div>
                <Copyright/>
            </div>

        </div>
        );
    }
}

export default ContentDevLog;