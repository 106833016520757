import React, { Component } from 'react';
import {Markup} from 'interweave';
import { withRouter } from 'react-router-dom';

import '../../styles/Components/Game/Game.css';

class Game extends Component {
    constructor() {
        super();
        // Ensure the this context is available via the onClick event
        this.followLink = this.followLink.bind(this);
    }

    render() {
        return (
            <div className = "game-frame">
                <figure className = "figure">
                    <img className = "game-thumbnail" src = {this.props.gameData.imageLink} alt = {this.props.gameData.gameName} />
                </figure>

                <div className = "game-mouse-over-info">
                    <div className = "game-more-info-button" role = "button" onClick = {this.followLink}>More Info</div>

                    <div className = "game-title">{this.props.gameData.gameName}</div>
                    <Markup content = {this.props.gameData.body} />
                </div>
            </div>
        );
    }

    followLink(){
        if (this.props.gameData.gameName === "Fhtagn! Tales of the Creeping Madness" ||
            this.props.gameData.gameName === "Fhtagn!"){
            let routeTarget = "/Fhtagn";
            let history = this.props.history;

            history.push(routeTarget);
            window.scrollTo(0, 0);
        }
        else if (this.props.gameData.gameName === "The Creeping Madness"){
            let routeTarget = "/TheCreepingMadness";
            let history = this.props.history;

            history.push(routeTarget);
            window.scrollTo(0, 0);
        }
        else if (this.props.gameData.gameName === "The Endless Wyrd"){
            let routeTarget = "/TheEndlessWyrd";
            let history = this.props.history;

            history.push(routeTarget);
            window.scrollTo(0, 0);
        }
        else{
            var win = window.open(this.props.gameData.gameLink, '_blank');
            win.focus();
        }
    }
}

export default withRouter(Game);