import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import Logo from './Logo';
import BackgroundTransition from './BackgroundTransition';

import '../styles/Components/Navigation.css'

class Navigation extends Component {
    constructor() {
        super();

        this.state = { mobileMenuOpen:false };

        // Ensure the this context is available via the onClick event
        this.toggleMenu = this.toggleMenu.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
    }

    render(){
        return(
            <div className = {"navigation-container" + (this.state.mobileMenuOpen ? "" : " closed")}>
                <BackgroundTransition position = "top" />
                <div className = "navigation">
                    <Logo logoType = "text"/>

                    <div className = "buttons">
                        <Link className = "navigation-button" role = "button" to = "/" onClick = {this.hideMenu}>About</Link>
                        <Link className = "navigation-button" role = "button" to = "/Games" onClick = {this.hideMenu}>Games</Link>
                        <Link className = "navigation-button" role = "button" to = "/DevLogs" onClick = {this.hideMenu}>Dev Logs</Link>
                        <Link className = "navigation-button" role = "button" to = "/Contact" onClick = {this.hideMenu}>Contact</Link>
                    </div>

                    <div className = {"mobile-menu-button" + (this.state.mobileMenuOpen ? " closed" : " open")} role = "button" onClick = {this.toggleMenu}></div>

                    <Logo logoType = "icon"/>
                </div>
            </div>
        );
    }

    toggleMenu(){
        // console.log("Toggle menu");
        let current = this.state.mobileMenuOpen;
        this.setState({mobileMenuOpen : !current});
    }

    hideMenu(){
        this.setState({mobileMenuOpen : false});
    }
}

export default Navigation;