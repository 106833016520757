import React, {Component} from 'react';

import '../styles/Components/Copyright.css';

class Copyright extends Component {
    render() {
        return (
            <div className = "copyright-notice">
                {/* Copyright Design Imps 2020<br/>Develped by <a href = "https://choseninteractive.com" target="_blank" rel="noopener noreferrer">Chosen Interactive</a> */}
                Copyright Design Imps 2020
            </div>
        );
    }
}

export default Copyright;