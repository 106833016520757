import React, { Component } from 'react';
import axios from 'axios';

import '../../styles/Components/Contact/ContactForm.css';
import { Markup } from 'interweave';

class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            subject: 'general',
            message: '',
            validName: true,
            validEmail: true,
            validMessage: true,
            showMessageNotice: false,
            messageSentResult: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleSubjectChange = this.handleSubjectChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);

        this.hidePostSentMessage = this.hidePostSentMessage.bind(this);
    }

    handleSubmit(e){
        /**
         * Using these methods:
         * WP Contact Form 7: https://medium.com/@mahesh_joshi/wordpress-contact-form-7-rest-api-endpoints-bf45907b571c
         * axios: https://blog.mailtrap.io/react-send-email/
         */
        e.preventDefault();

        if (this.validateForm()) {
            // console.log("NEED TO SUBMIT THE FORM");

            this.sendMessage();
        }
    }

    handleClear(e){
        // console.log("Need to clear the data.");
        this.setState({name: '', email: '', message: '', subject:'general', validName: true, validEmail: true, validMessage: true});
    }

    handleNameChange(e) { this.setState( {name: e.target.value} ); }

    handleEmailChange(e) { this.setState( {email: e.target.value} ); }

    handleSubjectChange(e) { this.setState( {subject: e.target.value} ); }

    handleMessageChange(e) { this.setState( {message: e.target.value} ); }

    validateEmail(email){
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return emailRegex.test(email);
    }

    validateForm(){
        // console.log("Validating:");
        // console.log(this.state);

        // let validationReport = '';
        let nValid = true;
        let eValid = true;
        let mValid = true;

        if (this.state.name === "") { nValid = false; }
        if (this.state.message === "") { mValid = false; }
        if (!this.validateEmail(this.state.email)) { eValid = false; }

        this.setState({validName: nValid, validEmail: eValid, validMessage: mValid});
        return nValid && eValid && mValid;
    }

    render() {
        if(this.state.showMessageNotice){ return this.renderMessageResult(); }
        else{return this.renderForm();}
    }

    renderForm(){
        return (
            <div className = "contact-message-area">
                <div className ="contact-coulomb">
                    <Markup content = {this.props.contactContent} />
                </div>

                <div className ="contact-coulomb">
                    <div className = "contact-form-spacing"></div>
                    <h3>Send a message</h3>
                    <div className = "contact-line">
                        <div className = "contact-grouping">
                            <div className = "contact-lable">Name</div>
                            <input value = {this.state.name} className = {"message-input-line" + (this.state.validName? "" : " invalid")} type="text" id="form-name" name="form-name" placeholder="Your name" onChange = {this.handleNameChange} />
                        </div>
                        <div className = "contact-grouping">
                            <div className = "contact-lable">Email</div>
                            <input value = {this.state.email} className = {"message-input-line" + (this.state.validEmail? "" : " invalid")} type="text" id="form-email" name="form-email" placeholder="Your email" onChange = {this.handleEmailChange} />
                        </div>
                    </div>

                    <div className = "contact-line">
                        <div className = "contact-lable">Subject</div>
                        <select value = {this.state.subject} className = {"message-input-dropdown" + (this.state.validSubject? "" : " invalid")} id="form-subject" name="form-subject" placeholder="Choose an option" onChange = {this.handleSubjectChange}>
                            <option value="general">General inquiry</option>
                            <option value="contract">Fhtagn!</option>
                            <option value="neon-nexus">The Creeping Madness</option>
                            <option value="endless-wyrd">The Endless Wyrd</option>
                            <option value="other">Other</option>
                        </select>
                    </div>

                    <div className="contact-line">
                        <div className="contact-lable">Message</div>
                        <textarea value = {this.state.message} className = {"message-input-text" + (this.state.validMessage? "" : " invalid")} id="form-message" name="form-message" placeholder = "Please type your message here..." onChange = {this.handleMessageChange}></textarea>
                    </div>

                    <div className = "message-buttons">
                        <input className = "message-button" type="submit" value="Submit" onClick = {this.handleSubmit}/>
                        <input className = "message-button" type="reset" value="Clear" onClick = {this.handleClear} />
                    </div>
                </div>
            </div>
        );
    }

    renderMessageResult(){
        return (
            <div className = "contact-message-area">
                <div className = "contact-message-sent-result">
                    <Markup content = {this.state.messageSentResult} />
                </div>
                <div className = "contact-message-result-close" role="button" onClick = {this.hidePostSentMessage}>Close</div>
            </div>
        );
    }

    sendMessage(){
        // console.log("Trying to send the message");

        // Fixing problems with validation errors:
        // https://wordpress.org/support/topic/using-rest-api-contact-form-7-in-nodejs-app/#post-10767538

        // Create new instance
        let bodyFormData = new FormData()
        // Set key:value pair according to your input:value form's fields
        bodyFormData.set( 'your-name', this.state.name )
        bodyFormData.set( 'your-email', this.state.email )
        bodyFormData.set( 'your-subject', this.state.subject )
        bodyFormData.set( 'your-message', this.state.message )

        axios({
            method: "POST",
            url:"https://designimps.com/imps_content/wp-json/contact-form-7/v1/contact-forms/5/feedback",
            data: bodyFormData
        }).then((response)=>{
            // console.log("axios response:");
            // console.log(response);
            if (response.data.status === 'mail_sent'){
                this.showPostSentMessage(true);
            }
            else{
                console.error("Message sent error: " + response.data.message);
                this.showPostSentMessage(false);
            }
        })
    }

    showPostSentMessage(sentSuccess){
        if(sentSuccess === true){
            this.setState({
                name: '',
                email: '',
                message: '',
                subject:'general',
                validName: true,
                validEmail: true,
                validMessage: true,

                showMessageNotice: true,
                messageSentResult: '<h2>Thank you for your message!</h2><p>We will get back to you shortly.</p>'
            });
        }
        else{
            this.setState({
                showMessageNotice: true,
                messageSentResult: '<h2>Your message could not be sent</h2><p>There was a problem sending your message, please try again later.</p><p>If the problem persists, please contact us at support@choseninteractive.com</p>'
            });
        }
    }

    hidePostSentMessage(){
        this.setState({ showMessageNotice: false });
    }
}

export default ContactForm;